.search-bar {
    position: relative;
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  
  .search-input {
    width: 100%;
    padding: 12px 40px 12px 16px; /* Add space for the icon */
    border: 2px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    
  }
  
  .search-input:focus {
    outline: none;
    border-color: #007BFF; /* Change border color on focus */
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  }
  
  .search-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    font-size: 18px;
    pointer-events: none; /* Make sure the icon doesn't block input clicks */
  }