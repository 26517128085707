/* src/MapComponent.css */
  .map-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
  
  }

  .select-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px 20px;
    z-index: 11000;
    display: flex;
    gap: 30px;
  }

  .loader-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000; /* Ensure the loader is on top */
    background-color: rgba(255, 255, 255, 0.7); /* Slight background overlay */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .loader-overlay p {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #36d7b7;
  }

  .select-keyword,.select-executing-party,.select-publication-date{
    width: 250px;
  }

  
  
/* MapComponent.css */
.popup {
  direction: rtl;
  max-width: 400px;
  padding: 10px;
  font-size: 1.1rem;
}

.popup__name {
  font-weight: bold;
  font-size: 1.4rem; /* Increase the font size for the place */
}

.popup__executingParty{

  text-align: start;
  font-size: 0.8rem; 
  color: #ac2525;
}

.popup__wrapper{
  
  width: 300px;
  text-align: start;
}

.popup__keyword {
  font-size: 1.2rem; /* Keep the font size consistent */
  color: #666;
}

.popup__description {
  margin-top: 10px;
  font-size: 0.9rem;
}

.popup__time {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #999;
}

.loader-map{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #007bff;
}




