.filter-component {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .select-keyword, .select-executing-party, .date-picker {
    flex: 1;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .select-keyword .react-select__control, 
  .select-executing-party .react-select__control {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    font-size: 16px;
   
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .custom-date-picker {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .date-input {
    padding-right: 30px; /* Add space for the icon inside the input */
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .calendar-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #333;
  }
  
  .date-picker {
    display: flex;
    flex-direction: column;
  }
  
  .date-picker label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
  }
  
  .filter-component h3 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  