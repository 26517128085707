/* Sidebar.css */

.sidebar {
  position: fixed;
  right: 0;
  width: 390px;
  background-color: #f9fafb;
  padding: 15px;
  z-index: 10000;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out; /* Smooth transition */
  font-family: 'Roboto', sans-serif;
  transform: translateX(100%); /* Initially hidden */
}

.sidebar.show {
  transform: translateX(0); /* Show sidebar */
}

.sidebar.hide {
  transform: translateX(100%); /* Hide sidebar */
}

.toggle-button {
  position: fixed;
  top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  z-index: 10001; /* Above sidebar */
  transition: right 0.3s ease-in-out; /* Smooth transition for right position */
}

.toggle-button.show {
  right: 390px; /* When sidebar is visible */
}

.toggle-button.hide {
  right: 0; /* When sidebar is hidden */
}

.toggle-button:hover {
  background-color: #0056b3;
}

.sidebar-container {
  direction: rtl;
}

.sidebar-item-selected {
  position: relative;
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  border-right: 5px solid #ff6347; /* Softer red */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Shadow for depth */
  transition: transform 0.2s, box-shadow 0.2s;
  font-size: 16px; /* Larger font for selected items */
  line-height: 1.5; /* Better readability */
}
.remove-button{
position: absolute;
top: 0;
right: 0;
font-size: 23px;
cursor: pointer;

}

.sidebar-item {
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  border-right: 5px solid #007bff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  font-size: 15px;
  line-height: 1.4;
  cursor: pointer;
}

.sidebar-item:hover {
  transform: translateY(-3px); /* Hover effect */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.header {
  display: flex;
  align-items: center;
  gap: 5px; /* Increased gap for spacing */
  font-size: 18px;
  font-weight: 700; /* Bold font for emphasis */
  color: #23384f; /* Darker font color for contrast */
}

.keyword {
  font-size: 15px;
  font-weight: bold;
  color: #007bff; /* Blue color to stand out */
  background-color: #e6f0ff; /* Light blue background to highlight keywords */
  padding: 5px 8px;
  border-radius: 5px;
   
}

.executing-party {
  color: #ff6347; /* Softer red */
  font-size: 16px;
  font-weight: 700; /* Increased font weight */
}

.operation-description {
  margin-bottom: 15px; /* Increased spacing */
  font-size: 15px; /* Slightly larger font for better readability */
  color: #555; /* Darker grey for readability */
  line-height: 1.6; /* Better line height for readability */
}

.publication-date {
  font-size: 13px; /* Slightly increased size */
  color: #777; /* Slightly darker text */
  text-align: left;
}

.loader {
  text-align: center;
  padding: 15px; /* More padding for better spacing */
  font-size: 15px;
  color: #007bff;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 3px; /* Rounded highlight */
}

.post-image {
  width: 100%;
  max-width: 320px; /* Increased width for images */
  height: auto;
  margin-top: 15px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow around the image */
}

.post-video {
  width: 100%;
  max-width: 320px;
  margin-top: 15px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for the video */
  overflow: hidden; /* Make sure the video fits the container */
}

.sidebar-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.sidebar-button {
  display: flex;
  align-items: center;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-weight: 500;
  color: #555;
}

.sidebar-button.active {
  border-bottom: 2px solid #1a1a1a;
  color: #1a1a1a;
}

.sidebar-button .icon {
  margin-right: 8px;
  font-size: 18px;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Style for the Load More button */
.load-more-button {
  background-color: #007bff; /* Primary blue color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.load-more-button:active {
  background-color: #004085; /* Even darker on click */
}

.load-more-button:disabled {
  background-color: #cccccc; /* Gray out when disabled */
  cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .sidebar {
    max-width: 300px;
  }

  .toggle-button.show {
    right: 300px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    max-width: 250px;
  }

  .toggle-button.show {
    right: 250px;
  }

  .header {
    font-size: 16px;
  }

  .sidebar-item,
  .sidebar-item-selected {
    padding: 15px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100%; /* Full width on small screens */
    max-width: none;
  }

  .toggle-button.show {
    right: 0; /* Overlay toggle button */
  }

  .toggle-button {
    padding: 6px;
    font-size: 15px;
  }

  .header {
    font-size: 15px;
  }

  .sidebar-item,
  .sidebar-item-selected {
    padding: 10px;
    font-size: 13px;
  }

  .post-image,
  .post-video {
    max-width: 100%;
  }
}



